import React, { Component } from "react";
import Hero from '../components/Hero/Hero'

const Airdrop = () => {
    return (
        <>
    
        <Hero />
        <h1 className="text-center font-bold text-orange-600">AIRDROP COMING SOON!</h1>
        </>
        
    )
}

export default Airdrop;